<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: medicine.vue
Description:This file is no longer in use
-->
<template>
  <div class="stock-medicine-view">
    <div>
      <medicine-table
        :tabData="tabData"
        :expandRow="expandRow"
        :addmedicineStock="addMedicineStock"
        @handleOpenDialog="handleEditResource"
      ></medicine-table>
      <component
        :is="`medicineAddDialog`"
        :action-to-perform="actionToPerform"
        :show-dialog="dialogVisible"
        :medicineStock="selectedMedicineStock"
        @date-change="handelDateChange"
        @close_dialog="closeDialog"
        @update-stock-medicine="handleUpdateStockMedicine"
        @add-stock-medicine="handleAddStockMedicine"
      ></component>
    </div>

    <div class="bottom-container">
      <p v-html="$t('Medicines_abbr')"></p>
    </div>
  </div>
</template>

<script>
import medicineActionBar from "@/components/stock/medicineActionBar";
import medicineTable from "@/components/stock/medicineTable";
import medicineAddDialog from "@/components/stock/medicineAddDialog";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { medicineActionBar, medicineTable, medicineAddDialog },
  props: ['tabData'],
  mixins: [errorHandlerMixin],
  data: function () {
    return {
      loading: false,
      dialogVisible: false,
      expandRow: undefined,
      actionToPerform: "",
      selectedMedicineStock: {},
      addMedicineStock: 0
    };
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation"
    }),
    location: function () {
      return this.getCurrUserLocation;
    }
  },

  methods: {
    ...mapActions("stock", {
      fetchIndividualMedicineLog: "fetchIndividualMedicineLog",
      fetchMedicineStocks: "fetchMedicineStocks"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handleEditResource(actionToPerform, resource) {
      this.selectedMedicineStock = resource;
      this.actionToPerform = actionToPerform;
      this.dialogVisible = true;
    },
    closeDialog(event) {
      this.dialogVisible = false;
    if (this.actionToPerform === 'add' && event !== 'close') {
        this.mixPanelEventGenerator({ eventName: "Stock - Medicines Table - Add Feed - Save" });
      }
      if (this.actionToPerform === 'edit' && event !== 'close') {
        this.mixPanelEventGenerator({ eventName: "Stock - Medicines Table - Edit - Save" });
      }
    },
    handelDateChange(data) {
      if (this.actionToPerform === 'add') {
        if (data === 'AddRow') {
          this.mixPanelEventGenerator({ eventName: "Stock - Medicines Table - Add Feed - Add Row" });
        } else {
          this.mixPanelEventGenerator({ eventName: "Stock - Medicines Table - Add Feed - Date Filter" });
        }
      }
      if (this.actionToPerform === 'edit') {
        if (data === 'AddRow') {
          this.mixPanelEventGenerator({ eventName: "Stock - Medicines Table - Edit - Add Row" });
        } else {
          this.mixPanelEventGenerator({ eventName: "Stock - Medicines Table - Edit - Date Filter" });
        }
      }
    },
    async handleUpdateStockMedicine(resource) {
      this.expandRow = {
        resourceId: resource.resourceId,
        changedStockmedicineqty: resource.changeInStock,
        changedStockId: resource.id
      };
    },

    handleAddStockMedicine() {
      this.addMedicineStock++;
    }
  }
};
</script>
<style lang="scss">
.stock-medicine-view {
  .bottom-container {
    opacity: 0.4;
    font-weight: bold;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }

  .input--search {
    padding: 0px;
    margin-right: 10px;
  }
}
</style>
