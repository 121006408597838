<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: medicineTable.vue
Description:  This file contains UI components of medicine table built using Element UI table
-->
<template>
  <el-row class="stock-medicine-view">
    <!-- type="white-header-table" -->

    <layout-toolbar type="flex" justify="end" class="post-header-right">
      <el-col :span="4.5">
        <er-input
          inputType="search"
          v-model="searchmedicine"
          size="mini"
          suffix-icon="el-icon-search"
          :placeholder="$t('Comn_search')"
          @change="handelSearchInput"
        ></er-input>
      </el-col>

      <er-action-button
        @action-btn-click="handleAddMedicine()"
        :type="'solid'"
        :disabled="isReadOnly"
      >
        <template v-slot:icon>
          <img style="margin-top: 4px" src="@/assets/button/add.svg" />
        </template>
        <template v-slot:label>
          <p style="text-transform: uppercase">
            {{ $t("Stock_add_med") }}
          </p>
        </template>
      </er-action-button>
    </layout-toolbar>
    <Loader v-if="loading"></Loader>
    <el-row v-if="!loading || tableDataArray.length > 0">
      <er-data-tables
        ref="medicinefeedtable"
        v-loading="loading"
        uniqueId="medicine-feed-table"
        type="white-header-table"
        element-loading-background="white"
        :table-data="tableDataArray"
        :columns="tableColumns"
        :el-table-props="tableProps"
        :pagination-props="$constants.table['pagination-props']"
        :actionColumn="false"
        :key="$i18n.locale"
        row-key="_id"
        :expand-column="true"
        :searchOnTableData="searchmedicine"
        @expand-change="handleExpandChange"
      >
        <template v-slot:name="{ row }">
          <er-highlight-search-component
            :highlightStrings="row.data.highlightStrings"
            :individualRowData="row.data"
            keyName="name"
            :withoutHighlightStrings="row.data.name"
          />
        </template>
        <template v-slot:stock_quantity="{ row }">
          <er-highlight-search-component
            :highlightStrings="row.data.highlightStrings"
            :individualRowData="row.data"
            keyName="stock_quantity"
            :withoutHighlightStrings="`${ftm__digitPrecision(
              row.data.stock_quantity,
              2
            )} ${getUnits(row.data.units)}`"
          />
        </template>
        <template v-slot:last_purchased_quantity="{ row }">
          <er-highlight-search-component
            :highlightStrings="row.data.highlightStrings"
            :individualRowData="row.data"
            keyName="last_purchased_quantity"
            :withoutHighlightStrings="`${ftm__formatEmpty(
              ftm__digitPrecision(row.data.last_purchased_quantity, 2)
            )}   ${
              row.data.last_purchased_quantity ? getUnits(row.data.units) : ''
            }`"
          />
        </template>
        <template v-slot:last_updated_at="{ row }">
          {{ lastUpdatedDate(row.data.last_updated_at) }}
        </template>

        <template v-slot:expandColumn="{ row }">
          <er-data-tables
            v-loading="expandloading === row.data._id"
            :tableData="getExpandedTableData(row.data)"
            :columns="expandedTableColumns"
            :el-table-props="tableProps_expand_table"
            :action-column="false"
            unique-id="medicine-inner-table"
            type="white-header-table-inner"
            layout="table"
          >
            <template v-slot:number_of_units="{ row: row1 }">
              {{ row1.data.number_of_units }}
            </template>

            <template v-slot:unit_quantity="{ row: row1 }">
              {{ row1.data.unit_quantity }} {{ getUnits(row1.data.units) }}
            </template>

            <template v-slot:quantity_purchased="{ row: row1 }">
              {{ row1.data.quantity_purchased }} {{ getUnits(row1.data.units) }}
            </template>

            <template v-slot:vendor_name="{ row: row1 }">
              {{
                typeof getObjVendors[row1.data.vendor_id] !== "undefined"
                  ? getObjVendors[row1.data.vendor_id].name
                  : "--"
              }}
            </template>

            <template v-slot:date="{ row: row1 }">
              {{ lastUpdatedDate(row1.data.date) }}
            </template>

            <template v-slot:actions="{ row: row1 }">
              <er-button
                type="text"
                size="mini"
                btnType="edit"
                :showLabel="true"
                :showIcon="true"
                @click="handleEditMedicineObj(row1.data)"
              ></er-button>
              <el-divider direction="vertical"></el-divider>
              <er-button
                type="text"
                size="mini"
                btnType="delete"
                :showLabel="true"
                :showIcon="true"
                @click="handleDeleteMedicineObj(row1.data)"
              ></er-button>
            </template>
          </er-data-tables>
        </template>
      </er-data-tables>
    </el-row>
  </el-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import filtersMixin from "@/mixins/filtersMixin.js";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import notificationMixin from "@/mixins/notificationMixin";
import Loader from "@/components/base/Loader";
export default {
  props: {
    expandRow: {
      default: undefined
    },
    addmedicineStock: {
      default: undefined
    },
    tabData: {
      default: undefined
    }
  },
  components: { Loader },
  mixins: [
    filtersMixin,
    errorHandlerMixin,
    userPreferenceMixin,
    datesHandlerMixin,
    notificationMixin
  ],
  data: function () {
    return {
      loading: false,
      expandloading: "",
      searchmedicine: "",
      expandedTableDataArr: {},
      allRowsCollapsed: true,
      tableProps: {
        size: "small",
        height: "calc(100vh - var(--table-gutter))"
      },
      tableProps_expand_table: {
        size: "small"
      },
      tableDataArray: [],
      tableDataObj: {},
      selectedValue: undefined
    };
  },
  watch: {
    addmedicineStock: async function (addStock) {
      if (addStock) {
        this.allRowsCollapsed = true;
        await this.initComponent();
      }
    },
    expandRow: async function (newResourceId) {
      if (
        !newResourceId.changedStockId &&
        !newResourceId.changedStockmedicineqty
      ) {
        return;
      }
      await this.initComponent();

      this.allRowsCollapsed = true;
      delete this.expandedTableDataArr[newResourceId.resourceId];
      this.$refs.medicinefeedtable.$refs.dataTables.$refs.elTable.toggleRowExpansion(
        this.tableDataObj[newResourceId.resourceId],
        true
      );
    }
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly",
      getCurrUserLocation: "getCurrUserLocation",
      getUserTimeZoneString: "getUserTimeZoneString"
    }),
    ...mapGetters("stock", {
      getAllMedicines: "getAllMedicines",
      getObjOfAllMedicines: "getObjOfAllMedicines",
      getIndividualMedicalSummary: "getIndividualMedicalSummary",
      getChangedAllMedicines: "getChangedAllMedicines"
    }),
    ...mapGetters("resource", {
      getObjVendors: "getObjVendors",
      getMedicineResources: "getMedicineResources",
      getArrVendors: "getArrVendors"
    }),
    getExpandedTableData() {
      return (medicineLog) => {
        const _id = medicineLog._id;
        if (_id) {
          return this.expandedTableDataArr[_id] || [];
        }
      };
    },
    lastUpdatedDate() {
      return (date) => {
        if (date && this.dhm__dateUtilsLib.isValid(new Date(date))) {
          return this.dhm__dateUtilsLib.formatDate(
            this.dhm__dateUtilsLib.parse(
              date,
              this.dhm__dateUtilsLib.isoFormatString,
              new Date()
            ),
            this.upm__getFormatDateString
          );
        }
      };
    },
    expandedTableColumns() {
      return {
        number_of_units: {
          prop: "number_of_units",
          label: this.$t("Comn_no_of_units"),
          minWidth: "90",
          align: "left"
        },
        unit_quantity: {
          prop: "unit_quantity",
          label: this.$t("Comn_unit_quantity"),
          minWidth: "100",
          align: "left"
        },

        quantity_purchased: {
          prop: "quantity_purchased",
          label: this.$t("Quantity_purchased"),
          minWidth: "100",
          align: "left"
        },
        vendor_name: {
          prop: "vendor_name",
          label: this.$t("Resource_vendor"),
          minWidth: "100",
          align: "left"
        },

        date: {
          prop: "date",
          label: this.$t("purchase_resource_date"),
          minWidth: "80",
          align: "left"
        },
        actions: {
          prop: "actions",
          label: this.$t("Comn_actions"),
          minWidth: "100",
          align: "left"
        }
      };
    },
    tableColumns() {
      return {
        name: {
          prop: "name",
          label: this.$t("Resource_name"),
          minWidth: "100",
          align: "left",
          enableSearch: true,
          searchProperty: "name"
        },
        stock_quantity: {
          prop: "stock_quantity",
          label: this.$t("Stock_avail_stock"),
          minWidth: "100",
          align: "left",
          enableSearch: true,
          searchProperty: "stock_quantity"
        },
        last_purchased_quantity: {
          prop: "last_purchased_quantity",
          label: this.$t("Stock_last_purchased_qty"),
          minWidth: "100",
          align: "left",
          enableSearch: true,
          searchProperty: "last_purchased_quantity"
        },
        last_updated_at: {
          prop: "last_updated_at",
          label: this.$t("Comn_last_updated"),
          minWidth: "80",
          align: "left"
        }
      };
    }
  },
  methods: {
    ...mapActions("stock", {
      fetchMedicineStocks: "fetchMedicineStocks",
      fetchIndividualMedicineLog: "fetchIndividualMedicineLog",
      deleteMedicineStock: "deleteMedicineStock"
    }),
    ...mapActions("resource", {
      fetchAllResources: "fetchAllResources",
      fetchAllVendors: "fetchAllVendors"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handelSearchInput() {
      if (this.searchmedicine !== '') {
        this.mixPanelEventGenerator({ eventName: "Stock - Medicines Table - Search" });
      }
    },
    handleChangeEdit() {
      this.edited = false;
    },
    getUnits(units) {
      const obj = {
        KG: "Kg",
        L: "L"
      };
      return obj[units];
    },

    async handleDeleteMedicineObj(resource) {
      try {
        this.$gblUAMCanUserDelete(this.tabData);
        await this.nofm__deleteConfirmation("resource", "Table");
        this.loading = true;
        await this.deleteMedicineStock(resource._id);
        const response = await this.fetchIndividualMedicineLog({
          id: resource.resource_id,
          location_id: this.getCurrUserLocation._id
        });
        await this.initComponent();
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Resource_deletion_successfully"),
          duration: 5000,
          type: "success"
        });
        console.log(response);
        if (response.data.resource.stock.length) {
          this.$set(
            this.expandedTableDataArr,
            resource.resource_id,
            this.getIndividualMedicalSummary
          );
          this.$nextTick(async () => {
            this.allRowsCollapsed = true;
            delete this.expandedTableDataArr[resource.resource_id];
            this.$refs.medicinefeedtable.$refs.dataTables.$refs.elTable.toggleRowExpansion(
              this.tableDataObj[resource.resource_id],
              true
            );
          });
        }
        this.mixPanelEventGenerator({ eventName: "Stock - Medicines Table - Delete" });
        if (response.data.resource.stock.length === 0) {
          this.allRowsCollapsed = true;
          await this.initComponent();
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    handleEditMedicineObj(event) {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        event.total_purchased = event.quantity_purchased;
        this.$emit("handleOpenDialog", "edit", this.$lodash.cloneDeep([event]));
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    handleAddMedicine() {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        if (
          this.getMedicineResources.length !== 0 &&
          this.getArrVendors.length !== 0
        ) {
          const initialmedicine = this.getAllMedicines.filter(
            (medicine) => medicine._id === this.getMedicineResources[0]._id
          );

          this.$emit(
            "handleOpenDialog",
            "add",

            [
              {
                resource_id: this.$lodash.cloneDeep(
                  this.getMedicineResources[0]._id
                ),
                number_of_units: this.$lodash.cloneDeep(1),
                vendor_id: this.$lodash.cloneDeep(this.getArrVendors[0]._id),
                quantity_purchased: this.$lodash.cloneDeep(10),
                unit_quantity: this.$lodash.cloneDeep(10),
                units: this.$lodash.cloneDeep(this.getMedicineResources[0].units),
                type: this.$lodash.cloneDeep(this.getMedicineResources[0].type),
                stock_quantity: this.$lodash.cloneDeep(
                  initialmedicine.length ? initialmedicine[0].stock_quantity : 0
                ),
                total_purchased: this.$lodash.cloneDeep(
                  initialmedicine.length
                    ? initialmedicine[0].stock_quantity + 10
                    : 10
                )
              }
            ]
          );
        } else {
          this.warnConfirm();
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      }
    },
    warnConfirm: function () {
      return this.$confirm(
        this.$t("please_add_resource_vendor"),
        this.$t("Comn_error"),
        {
          cancelButtonText: this.$t("Comn_cancel"),
          type: "warning"
        }
      );
    },
    async handleExpandChange(row, expandedrows) {
      const expdLen = expandedrows.filter((item) => !(item._id === row._id));
      if (expandedrows.length > 1) {
        this.$refs.medicinefeedtable.$refs.dataTables.$refs.elTable.toggleRowExpansion(
          expdLen[0],
          false
        );
        try {
          this.expandloading = row._id;
          // if (this.expandedTableDataArr[row._id]) return;
          await this.fetchIndividualMedicineLog({
            id: row._id,
            location_id: this.getCurrUserLocation._id
          });
          this.$set(
            this.expandedTableDataArr,
            row._id,
            this.getIndividualMedicalSummary
          );
        } catch (err) {
          this.ehm__errorMessages(err, true);
        } finally {
          this.expandloading = "";
        }
        return;
      }
      if (this.allRowsCollapsed) {
        this.allRowsCollapsed = false;
        try {
          this.expandloading = row._id;
          // if (this.expandedTableDataArr[row._id]) return;
          await this.fetchIndividualMedicineLog({
            id: row._id,
            location_id: this.getCurrUserLocation._id
          });
          this.$set(
            this.expandedTableDataArr,
            row._id,
            this.getIndividualMedicalSummary
          );
        } catch (err) {
          this.ehm__errorMessages(err, true);
        } finally {
          this.expandloading = "";
        }
      }
      if (expandedrows.length === 0) {
        this.allRowsCollapsed = true;
      }
    },

    async initComponent() {
      try {
        this.loading = true;
        await Promise.all([
          this.fetchAllResources({ get_all: true }),
          this.fetchAllVendors({ get_all: true }),
          this.fetchMedicineStocks({
            get_all: true,
            location_id: this.getCurrUserLocation._id
          })
        ]);
        this.tableDataArray = this.getAllMedicines.filter((item) =>
          this.getMedicineResources.some((item2) => item._id === item2._id)
        );
        this.tableDataObj = this.tableDataArray.reduce((acc, curr) => {
          acc[curr._id] = curr;
          return acc;
        }, {});
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {
    await this.initComponent();
  }
};
</script>

<style lang="scss">
.stock-medicine-view {
  @include responsiveProperty(--table-gutter, 200px, 226px, 234px);
  .el-table__body-wrapper {
    overflow-y: scroll;
  }
}
</style>
