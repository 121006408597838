<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: medicineAddDialog.vue
Description: This file contains UI components of add dialog from medicines page and it includes a form
-->
<template>
  <er-dialog
    :title="`${
      actionToPerform === 'edit' ? $t('Comn_edit') : $t('Comn_create')
    } ${$t('Stock_med_minerals_stock')}`"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @beforeClose="handleCloseDialog('close')"
    @open="handleDialogOpen"
    top="25vh"
    width="80%"
    class="medicine-add-dialog"
    custom-class="medicine-add-dialog-box"
  >
    <el-row>
      <el-row style="margin-bottom: 10px">
        <el-date-picker
          :clearable="false"
          size="mini"
          type="date"
          v-model="dateOfPurchase"
          :format="upm__getFormatDateString"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd"
          @change="handelDate"
        ></el-date-picker>
      </el-row>
      <el-row>
        <er-data-tables
          uniqueId="medicine_Add_Dialog"
          type="white-header-table"
          :table-data="tableDataArr"
          :el-table-props="tableProps"
          :columns="getColumns(tableColumns)"
          :actionColumn="false"
          layout="table"
        >
          <template v-slot:resource_name="{ row }">
            <er-select
              size="mini"
              v-model="row.data.resource_id"
              :placeholder="$t('Stock_med_select')"
              @change="handleChangeResource(row.data)"
              :disabled="actionToPerform === 'edit' ? true : false"
            >
              <el-option
                v-for="(resource, resource_id) in getMedicineObjResources"
                :label="resource.name"
                :value="resource_id"
                :key="resource_id"
              ></el-option>
            </er-select>
          </template>
          <template v-slot:number_of_units="{ row }">
            <el-input-number
              size="small"
              :min="1"
              :controls="false"
              v-model="row.data.number_of_units"
              @change="handleChangeInNoOfUnits(row.data)"
              placeholder="0"
            ></el-input-number>
          </template>
          <template v-slot:unit_quantity="{ row }">
            <el-input-number
              size="small"
              :min="1"
              :controls="false"
              v-model="row.data.unit_quantity"
              @change="handleChangeInQuantity(row.data)"
            ></el-input-number>
          </template>
          <!-- <template v-slot:units="{ row }">{{ row.data.units }}</template> -->

          <template v-slot:total_price="{ row }">{{
            ftm__digitPrecision(row.data.total_price, 2)
          }}</template>
          <template v-slot:vendor_id="{ row }">
            <er-select
              v-model="row.data.vendor_id"
              size="mini"
              :placeholder="$t('Resource_select_vendors')"
            >
              <el-option
                v-for="(vendor, vendor_id) in getObjVendors"
                :label="vendor.name"
                :value="vendor_id"
                :key="vendor_id"
              ></el-option>
            </er-select>
          </template>
          <template v-slot:units="{ row }">
            {{ getUnits(row.data.units) }}
          </template>
          <template v-slot:actions="{ row }">
            <template v-if="row.$index === tableDataLength - 1">
              <er-button
                icon="el-icon-plus"
                size="mini"
                @click="handleAddRow()"
                circle
              ></er-button>
            </template>
            <template v-else>
              <er-button
                icon="el-icon-minus"
                size="mini"
                circle
                @click="handleDeleteRow(row.$index)"
              ></er-button>
            </template>
          </template>
          <el-row slot="append" style="margin: 10px; text-align: right">
          </el-row>
        </er-data-tables>
      </el-row>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <div
        style="
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
        "
      >
        <p class="footer__content" v-show="actionToPerform === 'add'">
          {{ $t("Stock_total_stock_abbr") }}
        </p>
        <!-- <p class="footer__content" v-show="actionToPerform === 'edit'">
          {{ $t("Stock_edit_total_abbr") }}
        </p> -->
      </div>

      <div style="display: flex">
        <er-button
          size="mini"
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          :loading="loading"
          @click="handleSaveMedicine()"
        ></er-button>
        <er-button
          size="mini"
          btnType="cancel"
          :showLabel="true"
          @click="handleCloseDialog('close')"
        ></er-button>
      </div>
    </div>
  </er-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Medicine from "@/model/medicine";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin.js";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import notificationMixin from "@/mixins/notificationMixin";
export default {
  mixins: [
    filtersMixin,
    datesHandlerMixin,
    userPreferenceMixin,
    errorHandlerMixin,
    notificationMixin
  ],
  props: {
    medicineStock: {
      default: function () {
        return [new Medicine()];
      }
    },
    showDialog: {
      default: false
    },
    actionToPerform: {
      default: ""
    }
  },

  data: function () {
    return {
      tableDataArr: [new Medicine()],
      dateOfPurchase: "",
      total_purchased_stock: 0,
      loading: false
    };
  },

  computed: {
    ...mapGetters("stock", {
      getStateOfDialogBox: "getStateOfDialogBox",
      getAllMedicines: "getAllMedicines"
    }),
    ...mapGetters("resource", {
      getMedicineObjResources: "getMedicineObjResources",
      getObjVendors: "getObjVendors",
      getArrVendors: "getArrVendors",
      getMedicineResources: "getMedicineResources"
    }),
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation"
    }),
    pickerOptions() {
      return {
        disabledDate: function (time) {
          return time.getTime() > Date.now();
        }
      };
    },
    dialogVisibleStatus: {
      get() {
        return this.getStateOfDialogBox;
      },
      set(value) {
        this.changeDialogBoxVisibleStatus(value);
      }
    },
    tableProps: function () {
      return {
        size: "small"
      };
    },
    tableDataLength: function () {
      return this.tableDataArr.length;
    },
    tableColumns() {
      return {
        resource_name: {
          prop: "resource_name",
          label: this.$t("Resource_name"),
          minWidth: "100",
          align: "left",
          default: true
        },
        number_of_units: {
          default: true,
          prop: "number_of_units",
          label: this.$t("Comn_no_of_units"),
          minWidth: "95",
          align: "left"
        },
        unit_quantity: {
          prop: "unit_quantity",
          label: this.$t("Stock_bag_wgt"),
          minWidth: "100",
          align: "left",
          default: true
        },
        units: {
          prop: "units",
          label: this.$t("Comn_units"),
          minWidth: "70",
          align: "left",
          default: true
        },

        quantity_purchased: {
          prop: "quantity_purchased",
          label: this.$t("Stock_total_purchased"),
          minWidth: "75",
          align: "left",
          default: true
        },

        stock_quantity: {
          prop: "stock_quantity",
          label: this.$t("Stock_avail_stock"),
          minWidth: "65",
          align: "left",
          default: false
        },
        total_purchased: {
          prop: "total_purchased",
          label: this.$t("Comn_total_stock"),
          minWidth: "65",
          align: "left",
          default: false
        },
        vendor_id: {
          prop: "vendor_id",
          label: this.$t("Resource_vendor"),
          minWidth: "100",
          align: "left",
          default: true
        },
        actions: {
          prop: "actions",
          label: this.$t("Comn_actions"),
          minWidth: "50",
          align: "left",
          default: false
        }
      };
    },
    dialogVisible: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.handleCloseDialog("close");
      }
    }
  },
  methods: {
    ...mapActions("stock", {
      changeDialogBoxVisibleStatus: "changeDialogBoxVisibleStatus",
      createMedicine: "createMedicine",
      updateMedicine: "updateMedicine",
      fetchIndividualMedicineLog: "fetchIndividualMedicineLog",
      fetchMedicineStocks: "fetchMedicineStocks"
    }),
    handelDate() {
      this.$emit('date-change', 'true');
    },
    getUnits(units) {
      const obj = {
        KG: "Kg",
        L: "L"
      };
      return obj[units];
    },

    getColumns(input) {
      const objArray = [];
      Object.keys(input).forEach((key) => objArray.push(input[key]));
      if (this.actionToPerform === "edit") {
        const editArrayCol = objArray.filter((item) => item.default === true);
        const object = editArrayCol.reduce(
          (acc, item) => Object.assign(acc, { [item.prop]: item }),
          {}
        );
        return object;
      } else {
        return input;
      }
    },
    handleChangeResource(resource) {
      const result = this.getAllMedicines.filter(
        (item) => item._id === resource.resource_id
      );
      if (result.length) {
        resource.units = result[0].units;
        resource.type = result[0].type;
        resource.stock_quantity = result[0].stock_quantity;
        resource.total_purchased = result[0].stock_quantity
          ? result[0].stock_quantity + resource.quantity_purchased
          : resource.quantity_purchased;
      } else {
        const result = this.getMedicineResources.filter(
          (item) => item._id === resource.resource_id
        );
        resource.units = result[0].units;
        resource.type = result[0].type;
        resource.stock_quantity = 0;
        resource.total_purchased = result[0].stock_quantity
          ? result[0].stock_quantity + resource.quantity_purchased
          : resource.quantity_purchased;
      }
    },
    handleDialogOpen() {
      this.tableDataArr = this.medicineStock;
      this.total_purchased_stock = this.medicineStock[0].total_purchased;
      this.stock_quantity = this.medicineStock[0].stock_quantity;
      this.dateOfPurchase =
        this.actionToPerform === "add"
          ? this.dhm__formatTZ(
              this.dhm__dateUtilsLib.getCurrDateInGivenTZ(
                this.getUserTimeZoneString
              ),
              "yyyy-MM-dd"
            )
          : this.dhm__formatTZ(
              new Date(this.medicineStock[0].date),
              "yyyy-MM-dd"
            );
    },

    handleCloseDialog(event) {
      this.tableDataArr = [new Medicine()];
      this.$emit("close_dialog", event);
    },

    handleAddRow() {
      const initialmedicine = this.getAllMedicines.filter(
        (medicine) => medicine._id === this.getMedicineResources[0]._id
      );

      this.tableDataArr.push({
        resource_id: this.$lodash.cloneDeep(this.getMedicineResources[0]._id),
        number_of_units: this.$lodash.cloneDeep(1),
        vendor_id: this.$lodash.cloneDeep(this.getArrVendors[0]._id),
        quantity_purchased: this.$lodash.cloneDeep(10),
        unit_quantity: this.$lodash.cloneDeep(10),
        units: this.$lodash.cloneDeep(this.getMedicineResources[0].units),
        type: this.$lodash.cloneDeep(this.getMedicineResources[0].type),
        stock_quantity: this.$lodash.cloneDeep(
          initialmedicine.length ? initialmedicine[0].stock_quantity : 0
        ),
        total_purchased: this.$lodash.cloneDeep(
          initialmedicine.length ? initialmedicine[0].stock_quantity + 10 : 10
        )
      });
      this.$emit('date-change', 'AddRow');
    },
    handleDeleteRow(rowIndexPos) {
      this.tableDataArr.splice(rowIndexPos, 1);
    },
    handleChangeInNoOfUnits(stockMedicineObj) {
      stockMedicineObj.quantity_purchased =
        stockMedicineObj.number_of_units * stockMedicineObj.unit_quantity;

      // const total_purchased_stock = this.total_purchased_stock;

      if (this.actionToPerform === "add") {
        const stock_quantity = stockMedicineObj.stock_quantity;
        stockMedicineObj.total_purchased =
          stock_quantity + stockMedicineObj.quantity_purchased;
      } else {
        stockMedicineObj.total_purchased = stockMedicineObj.quantity_purchased;
      }

      // stockMedicineObj.total_purchased =
      //   total_purchased_stock + (stockMedicineObj.quantity_purchased - 10);
    },
    handleChangeInQuantity(stockMedicineObj) {
      stockMedicineObj.quantity_purchased =
        stockMedicineObj.number_of_units * stockMedicineObj.unit_quantity;

      if (this.actionToPerform === "add") {
        const total_purchased_stock = stockMedicineObj.stock_quantity;
        stockMedicineObj.total_purchased =
          total_purchased_stock + stockMedicineObj.quantity_purchased;
      } else {
        stockMedicineObj.total_purchased = stockMedicineObj.quantity_purchased;
      }
    },
    async handleSaveMedicine() {
      const medicine = this.tableDataArr.reduce((acc, medicine) => {
        medicine.date = this.dateOfPurchase + "T00:00:00.000Z";
        if (this.actionToPerform === "edit") {
          medicine.quantity_purchased = this.$lodash.cloneDeep(
            medicine.total_purchased
          );
        }
        medicine.location_id = this.getCurrUserLocation._id;
        acc.push(medicine);
        return acc;
      }, []);
      if (this.actionToPerform === "edit" && this.tableDataArr.length === 1) {
        try {
          this.loading = true;
          await this.updateMedicine(medicine[0]);
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: this.$t("Resource_updated_successfully"),
            duration: 5000,
            type: "success"
          });
          this.$emit("update-stock-medicine", {
            resourceId: medicine[0].resource_id,
            changeInstock: medicine[0].quantity_purchased,
            id: medicine[0]._id
          });
        } catch (err) {
          this.ehm__errorMessages(err, true);
        } finally {
          this.loading = false;
        }
      }
      if (this.tableDataArr.length >= 1 && this.actionToPerform === "add") {
        try {
          this.loading = true;
          await this.createMedicine(medicine);
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: this.$t("Resource_assigning_successfully"),
            duration: 5000,
            type: "success"
          });
          this.$emit("add-stock-medicine");
        } catch (err) {
          this.ehm__errorMessages(err, true);
        } finally {
          this.loading = false;
        }
      }
      this.handleCloseDialog("save");
    }
  }
};
</script>

<style lang="scss">
.medicine-add-dialog {
  .el-dialog__body {
    padding: 10px 20px;
  }
  .dialog-footer {
    display: flex;
    justify-content: space-between;
    .footer__content {
      opacity: 0.4;

      font-weight: bold;
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
    }
  }
  .el-input--small .el-input__inner {
    @include responsiveProperty(height, 28px, 32px, 40px);
    @include responsiveProperty(width, 100px, 110px, 120px);
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 130px;
  }
}
.medicine-add-dialog-box {
  border-radius: 10px;
}
</style>
